<template>
  <div class="questions pa-5">
    <h1 class="mb-10 primary--text">Fragenkatalog</h1>
    <v-list dense>
      <v-list-item v-for="category in categories" :key="category.id">
        <v-list-item-content>
          <router-link
            :to="'/fragenkatalog/' + category.id + '-' + category.slug"
          >
            {{ category.id }} {{ category.title }}
          </router-link>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import categoriesData from "../../public/data/categories.json";

export default {
  name: "Questionnaire",
  data: () => {
    return {
      categories: categoriesData,
    };
  },
};
</script>
